<template>
  <div class="d-flex flex-column align-items-center body-container text-center">
    <img class=" mt-1 col-12" src="../assets/game/main-banner.png">
    <img class="col-7" src="../assets/game/shirt-on-line.png">
    <div>There are no rounds to predict today.</div>
    <div class="col-10 mt-3">Predict more of the world's greatest leagues when you play <strong>Vodacom Fantasy League
      VIP</strong> today!
    </div>
    <button v-if="subscriptionStatus !== 'Subscribed'" @click="subscribeClicked()" class="join-vip-button mt-3 col-11">
      Play VIP for R3/day
    </button>
    <button v-if="subscriptionStatus === 'Subscribed'" class="join-vip-button mt-3 col-11"
            @click="playNowClicked">PLAY FANTASY FOOTBALL
    </button>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "NoRoundsPage",
  computed: {
    ...mapState(['subscriptionStatus', 'user'])
  },
  methods: {
    playNowClicked() {
      window.open(`${environmentConfig.predictorHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    subscribeClicked() {
      window.open(`${environmentConfig.entryPortalHost}`, '_self');
    },
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  }
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 120px;
}

.join-vip-button {
  background-color: #bfa668;
  color: #FFFFFF;
  height: 50px;
  border: 0;
  font-size: 16px;
}

</style>
